import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Formik } from "formik";
import { clone } from "underscore";

import { commonApiFN } from "../../common/api";
import { ResetPasswordApi } from "../../common/module_wise_api_url/onboarding";
import TextBox from "../../components/Textbox/Textbox";
import { toastify } from "../../helper/helper";

import BackArrow from "../../assets/svg/f-btn-arrow-gray.svg";
import ButtonCustom from "../../components/Button/Button";

const ResetPassword = (props) => {
  let query = new URLSearchParams(useLocation().search);
  let forgotPasswordToken = query.get("reset_password_token");
  let admin_email = decodeURIComponent(query.get("email"));
  const [isResettingPassword, setIsResettingPassword] = useState(false);
  const handleResetPassword = async (values) => {
    setIsResettingPassword(true);
    let requestData = clone(ResetPasswordApi);
    requestData.params = {
      user: {
        email: admin_email,
        password: values.password,
        password_confirmation: values.cpassword,
        reset_password_token: forgotPasswordToken,
      },
      admin: true,
    };
    const response = await commonApiFN(requestData);
    if (response.success) {
      if (response?.message) {
        toastify("error", response.message);
        props.history.push("/");
      }
    } else {
      if (response?.message) {
        toastify("error", response.message);
      }
    }
    setIsResettingPassword(false);
  };
  useEffect(() => {
    if (admin_email === null || forgotPasswordToken === null) {
      props.history.push("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forgotPasswordToken]);

  return (
    <div className="container text-center reset-section">
      <h1 className="title text-primary">Your password is reset</h1>
      <h2 className="subtitle text-primary mt-3">
        Choose your new password below.
      </h2>
      <Formik
        initialValues={{ password: "", cpassword: "" }}
        validate={(values) => {
          const errors = {};
          if (!values.password) {
            errors.password = "Please enter password";
          } else if (values.password.trim().length < 8) {
            errors.password = "Password should have at least 8 characters";
          } else if (!/(?=.*?[A-Z])(?=.*?[a-z])/.test(values.password)) {
            errors.password =
              "Password should have both upper and lowercase characters";
          }
          if (!values.cpassword) {
            errors.cpassword = "Please enter your confirmation Password";
          } else if (values.password !== values.cpassword) {
            errors.cpassword = "Password don't match";
          }
          return errors;
        }}
        onSubmit={(values, actions) => {
          handleResetPassword(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form
            onSubmit={handleSubmit}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                if (values.password === values.cpassword) {
                  handleSubmit();
                }
              }
            }}
          >
            <div className="form-group">
              <label> Enter your password </label>
              <TextBox
                name="password"
                type="password"
                placeholder="Password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                error={errors.password && touched.password}
                errorText={errors.password}
                id="forgot-input"
              />
            </div>
            <div className="form-group">
              <label> Confirm your password </label>
              <TextBox
                name="cpassword"
                type="password"
                placeholder="Password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.cpassword}
                error={errors.cpassword && touched.cpassword}
                errorText={errors.cpassword}
                id="forgot-input"
              />
            </div>
            <div className="btns-wrapper">
              <Link to="/" className="link-focus">
                <img
                  src={BackArrow}
                  id="BackArrow"
                  className="back-link"
                  width="18px"
                  alt="Back arrow"
                />
                <span>Back</span>
              </Link>
              <ButtonCustom
                type="primary"
                submit
                isSubmitting={isResettingPassword}
                customClassName="forgot-button"
                onClick={(e) => {
                  if (!errors.password && !errors.cpassword) {
                    if (values.password === values.cpassword) {
                      handleSubmit(e);
                    }
                  }
                }}
              >
                Reset password
              </ButtonCustom>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default ResetPassword;
