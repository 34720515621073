import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Rating from "react-rating";
import { Button } from "@mui/material";
import moment from "moment";

import CustomDialog from "../../components/CustomDialog/CustomDialog";
import TextboxCustom from "../../components/TextboxCustom/TextboxCustom";
import { toastify } from "../../helper/helper";
import usePromise from "../../hooks/usePromise/usePromise";
import {
  sendFeedBackAPICareer,
  sendFeedBackAPIAdmission,
} from "../../services/helpCenterServices";
import ButtonCustom from "../../components/Button/Button";

import BlueStarEmptyIcon from "../../assets/svg/blueStarEmpty.svg";
import BlueStarFillIcon from "../../assets/svg/blueStarFill.svg";

const FeedbackModal = ({ handleModal = () => {} }) => {
  const selectedPortal = useSelector(
    (state) => state.loginReducer.selectedPortal
  );

  const [showModal, setModal] = useState(true);
  const [showThanksModal, setThanksModal] = useState(false);
  const [currentRate, setRating] = useState(0);
  const [comment, setComment] = useState("");
  const [point, setPoint] = useState(0);
  const [isSubmittingFeedback, setIsSubmittingFeedback] = useState(false);
  const [callingSendFeedBackAPI, refreshSendFeedBackAPI] = usePromise(
    selectedPortal === "career"
      ? sendFeedBackAPICareer
      : sendFeedBackAPIAdmission
  );

  const handleSubmit = () => {
    setIsSubmittingFeedback(true);
    const payload = {
      send_feedback: {
        rate: currentRate,
        comment: comment,
        recommended_rate: point,
      },
    };
    if (currentRate > 0 && point > 0 && comment !== "") {
      refreshSendFeedBackAPI(payload);
    } else {
      toastify("error", "Please, fill all data");
    }
  };

  const handleClose = () => {
    // 2 days delay for next time popup
    localStorage.setItem(
      "feedback_dialog_timestamp",
      moment().add(2, "days").format("YYYY-MM-DD HH:mm")
    );
    handleModal(false);
  };

  const handleThanksClose = () => {
    setThanksModal(false);
    handleModal(false);
  };

  const renderPoints = (p, i) => {
    return (
      <li
        key={i}
        className={`point ${point === p && "active"} cursor-pointer`}
        onClick={() => setPoint(p)}
      >
        {p}
      </li>
    );
  };

  useEffect(() => {
    if (callingSendFeedBackAPI.hasFetched()) {
      if (callingSendFeedBackAPI.hasErrors()) {
        toastify("error", "Something went wrong");
      } else {
        setModal(false);
        setRating(0);
        setPoint(0);
        setComment("");
        setThanksModal(true);
      }
      setIsSubmittingFeedback(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callingSendFeedBackAPI.isFetching()]);

  return (
    <>
      {showModal && (
        <div className="feedback-model">
          <CustomDialog
            className="feedback-dialog"
            open={showModal}
            title="We’d love to hear your feedback"
            dialogTitleClass="modal-title"
            handleClose={handleClose}
          >
            <div className="model-body">
              <div className="rating-box">
                <div className="sub-header-primary">Give us a rating</div>
                <div className="custom-rating">
                  <Rating
                    start={0}
                    stop={5}
                    step={1}
                    emptySymbol={
                      <img src={BlueStarEmptyIcon} alt="Non-rated star" />
                    }
                    fullSymbol={<img src={BlueStarFillIcon} alt="Rated star" />}
                    placeholderSymbol={
                      <img src={BlueStarFillIcon} alt="Rate star" />
                    }
                    fractions={1}
                    value={currentRate}
                    placeholderRating={currentRate}
                    onClick={setRating}
                  />
                </div>
              </div>
              <div className="about-box">
                <TextboxCustom
                  name="about"
                  placeholder="We'd love to hear more from you. What did you like? What could use improvement? Any new features you'd like to see in the future? We're all ears!"
                  isMultiline={true}
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  label={"Tell us more*"}
                  lableaignment="center"
                  className="custom-textarea"
                  rows={1.4}
                  maxLength={500}
                />
              </div>
              <div className="friend-refer">
                <div className="sub-header-primary title">
                  Would you recommend Interstride to a colleague?
                </div>
                <ul>
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((point, index) =>
                    renderPoints(point, index)
                  )}
                </ul>
                <div className="like">
                  <div className="sub-header-primary ml-auto text-left">
                    Not Likely
                  </div>
                  <div className="sub-header-primary ml-auto text-right">
                    Extremely likely
                  </div>
                </div>
              </div>
              <div className="btn-submit-feedback">
                <ButtonCustom
                  variant="contained"
                  onClick={handleSubmit}
                  isDisabled={
                    currentRate < 1 || comment === "" || point < 1
                      ? true
                      : false
                  }
                  className={`${
                    currentRate < 1 || comment === "" || point < 1
                      ? "o-half"
                      : "o-full"
                  }`}
                  isSubmitting={isSubmittingFeedback}
                >
                  Submit feedback
                </ButtonCustom>
              </div>
              <div className="text-center">
                <a
                  href
                  className="text-cancel link-focus"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleClose();
                  }}
                >
                  Cancel
                </a>
              </div>
            </div>
          </CustomDialog>
        </div>
      )}

      {showThanksModal && (
        <div className="feedback-model">
          <CustomDialog
            className="feedback-dialog"
            open={showThanksModal}
            title="Thanks for sharing your feedback!"
            handleClose={handleThanksClose}
          >
            <div className="model-body">
              <div className="feedback-reply">
                <div className="sub-header-primary">
                  We value your opinion and will review your feedback soon.
                </div>
              </div>
              <div className="btn got-it-btn">
                <Button variant="contained" onClick={handleThanksClose}>
                  Got it!
                </Button>
              </div>
            </div>
          </CustomDialog>
        </div>
      )}
    </>
  );
};

export default FeedbackModal;
