import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import {
  SortableContainer,
  SortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import moment from "moment";

import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import RejectedIcon from "@mui/icons-material/Cancel";
import AssignedIcon from "@mui/icons-material/CheckCircle";
import { Tooltip } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { makeStyles } from "@mui/styles";
import arrayMove from "array-move";
import { sortBy, isNull, isEmpty } from "underscore";

import ButtonCustom from "../../components/Button/Button";
import Dialog from "../../components/Dialog/Dialog";
import { JOB_POSITION_OPTIONS } from "../../Constants";
import { capitalizeFirstLetter, getClickableLink } from "../../helper/helper";
import CheckboxCustom from "../Checkbox/Checkbox";
import IconButton from "../IconButton/IconButton";

import RSS from "../../assets/img/handshake_rss_logo.png";
import Add from "../../assets/svg/Add-Icon.svg";
import Allow from "../../assets/svg/YesChecked.svg";
import Available from "../../assets/svg/Available.svg";
import Delete from "../../assets/svg/Delete.svg";
import DeleteWhite from "../../assets/svg/DeleteWhite.svg";
import Deny from "../../assets/svg/NoClose.svg";
import dragIcon from "../../assets/svg/drag.svg";
import Edit from "../../assets/svg/Edit.svg";
import ExportIcon from "../../assets/svg/exportWhite.svg";
import EyeIcon from "../../assets/svg/EyeIcon.svg";
import Linkedin from "../../assets/svg/linkedin-circled.svg";
import MentorNo from "../../assets/svg/Mentor-No.svg";
import MentorYes from "../../assets/svg/Mentor-Yes.svg";
import Notavailable from "../../assets/svg/Notavailable.svg";
import MessageIcon from "../../assets/svg/NetworkIcon.svg";
import Restore from "../../assets/svg/Restore.svg";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function BasicTable({
  approveRequestFn = () => {},
  denyRequestFn = () => {},
  toggleMentorFn = () => {},
  exportOption = true,
  exportIDs = null,
  keyField = "id",
  selectiveAction = false,
  equalSubTableSpacing = false,
  searchNotFound,
  messageIconTooltipText = "Message",
  ...props
}) {
  const { data_keys } = props;

  const [data, setData] = useState(props.data);
  const [editData, setEditData] = useState();
  const [header, setHeader] = useState(props.header);
  const [openDialog, setOpenDialog] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [selectedRecords, setSelectedRecords] = useState([]);
  const [sortSelectedType, setSortSelectedType] = useState(null);
  const [getID, setID] = useState();
  const [clickedId, setClickedId] = useState(null);
  const classes = useStyles();

  const totalRecords = exportIDs !== null ? exportIDs?.length : data?.length;
  const allChecked =
    selectedRecords?.length === totalRecords && totalRecords !== 0
      ? true
      : false;

  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  useEffect(() => {
    if (exportIDs && exportIDs !== null && exportOption) setSelectedRecords([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exportIDs]);

  const sortingFn = (type, key) => {
    if (props.changeSortingFn) {
      props.changeSortingFn(type, data_keys[key].name);
    } else {
      // case-insensitive sorting
      const sortedAsc = sortBy(data, (i) =>
        typeof i[data_keys[key].name] === "string"
          ? i[data_keys[key].name].toLowerCase()
          : i[data_keys[key].name]
      );
      setData(type === "asc" ? sortedAsc : sortedAsc.reverse());
    }

    header[key].sortType = !header[key].sortType;
    setHeader([...header]);

    setClickedId(null);
  };

  const DeleteFn = () => {
    if (openDialog === "One") {
      props.confirmDelete && props.confirmDelete(getID);
      setClickedId(null);
    } else if (openDialog === "Archive") {
      props.confirmArchive && props.confirmArchive(getID);
      setClickedId(null);
    } else if (openDialog === "Restore") {
      props.confirmRestore && props.confirmRestore(getID);
      setClickedId(null);
    } else {
      props.bulkDelete && props.bulkDelete(selectedRecords);
    }

    setOpenDialog(false);
  };

  const SortableView = (props) => {
    return (
      <>
        {props.type ? (
          <ArrowDownwardIcon
            className="mui-sortable-icons"
            onClick={() => {
              sortingFn("asc", props.name);
            }}
          />
        ) : (
          <ArrowUpwardIcon
            className="mui-sortable-icons"
            onClick={() => {
              sortingFn("desc", props.name);
            }}
          />
        )}
      </>
    );
  };

  const DragHandle = sortableHandle(() => (
    <span>
      <img src={dragIcon} alt="drag" />
    </span>
  ));

  const SubPartData = ({ items }) => {
    const [subData, setSubData] = useState(items);

    const onSubSortEndFn = ({ oldIndex, newIndex }) => {
      setSubData(arrayMove(subData, oldIndex, newIndex));
    };

    return (
      <SortableSubList
        items={subData}
        onSortEnd={onSubSortEndFn}
        useDragHandle
      />
    );
  };

  const SortableSubList = SortableContainer(({ items }) => {
    return (
      <>
        {items &&
          items.length &&
          items.map((value, index) => (
            <SortableSubMenu
              key={"item" + value.id}
              index={index}
              value={value}
            />
          ))}
      </>
    );
  });

  const SortableSubMenu = SortableElement(({ value }) => {
    return (
      <>
        {redirect && (
          <Redirect
            to={{
              pathname: props.editRouting,
            }}
          />
        )}
        <TableRow key={value.id}>
          <TableCell>
            <Table>
              <TableRow>
                <TableCell>
                  <DragHandle />
                </TableCell>
                {data_keys &&
                  data_keys.length &&
                  data_keys.map((key, k_index) =>
                    value[key.name] && value[key.name] !== " " ? (
                      <TableCell className={!exportOption ? "first-child" : ""}>
                        {value[key.name]}
                      </TableCell>
                    ) : (
                      <TableCell>
                        <Tooltip title="Edit">
                          <a href onClick={() => setRedirect(true)}>
                            <img src={Edit} alt="Edit" />
                          </a>
                        </Tooltip>
                        <Tooltip title="Delete">
                          <a href onClick={() => setOpenDialog("One")}>
                            <img src={Delete} alt="Delete" />
                          </a>
                        </Tooltip>
                      </TableCell>
                    )
                  )}
              </TableRow>
            </Table>
          </TableCell>
        </TableRow>
      </>
    );
  });

  const particularCheckBoxFn = (key) => {
    let cloneValues = [...selectedRecords];
    if (cloneValues.includes(key)) {
      cloneValues.splice(
        cloneValues.findIndex((rec) => rec === key),
        1
      );
    } else {
      cloneValues.push(key);
    }

    setSelectedRecords([...cloneValues]);
  };

  const callDelete = (id) => {
    setOpenDialog("One");
    setID(id);
  };

  const callArchive = (id) => {
    setOpenDialog("Archive");
    setID(id);
  };

  const callRestore = (id) => {
    setOpenDialog("Restore");
    setID(id);
  };

  const selectAllFn = (checked = !allChecked) => {
    if (checked) {
      setSelectedRecords([...exportIDs]);
    } else {
      setSelectedRecords([]);
    }
  };

  return (
    <>
      {redirect && (
        <Redirect
          to={{
            pathname: props.editRouting,
            search: "id=" + editData.id,
            state: editData,
          }}
        />
      )}
      <div
        className={`custom-table ${
          !exportOption ? "table-without-checkbox" : ""
        } ${data?.length === 0 ? "empty-table" : ""}`}
      >
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="table">
            {data && data.length === 0 ? (
              <div className="EmptyMessage text-center">
                <h2>
                  {searchNotFound
                    ? "No matching results found."
                    : props.title === "Pending Requests"
                    ? "No pending requests found."
                    : props.title === "Lead"
                    ? "No leads found."
                    : "Looks like there’s nothing here yet."}
                </h2>
                <h3>
                  {searchNotFound && props.title === "Pending Requests"
                    ? "Refine your search to find suitable pending requests."
                    : props.title === "Pending Requests"
                    ? [
                        "Pending requests will be listed here when any student or alumni",
                        <br />,
                        "attempts to register from domains not whitelisted.",
                      ]
                    : searchNotFound && props.title === "Lead"
                    ? "Refine your search to find suitable leads."
                    : props.title === "Lead"
                    ? "Leads will be listed here when any student from non-partner schools attempts to register."
                    : props?.title?.includes("Feedback") ||
                      props?.title?.includes("Suggestion")
                    ? ""
                    : `Create a New ${props.title} to get started.`}
                </h3>
              </div>
            ) : (
              <>
                <TableRow className="thead-row">
                  <TableCell>
                    <Table>
                      <TableRow>
                        {exportOption && (
                          <TableCell className="checkbox-all-cell">
                            {!props.tableDrag && (
                              <CheckboxCustom
                                value={allChecked ? true : false}
                                onClick={() => {
                                  selectAllFn();
                                }}
                                isChecked={allChecked ? true : false}
                              />
                            )}
                          </TableCell>
                        )}

                        {header &&
                          header.length &&
                          header.map((h_data, h_index) => (
                            <>
                              {h_data.name === "buttonAction" ? (
                                <TableCell className="button-action-header"></TableCell>
                              ) : (
                                <TableCell
                                  onClick={() => {
                                    setSortSelectedType(h_index);
                                  }}
                                  className={`${
                                    h_data.sortIcon ? "cursor-pointer" : ""
                                  }`}
                                >
                                  <h5
                                    className={
                                      sortSelectedType === h_index
                                        ? "active"
                                        : ""
                                    }
                                  >
                                    {h_data.name}
                                    {h_data.sortIcon &&
                                      sortSelectedType === h_index && (
                                        <SortableView
                                          name={h_index}
                                          type={h_data.sortType}
                                        />
                                      )}
                                    {/* <svg class="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M20 12l-1.41-1.41L13 16.17V4h-2v12.17l-5.58-5.59L4 12l8 8 8-8z"></path></svg> */}
                                  </h5>
                                </TableCell>
                              )}
                            </>
                          ))}
                      </TableRow>
                    </Table>
                  </TableCell>
                </TableRow>
                {props.tableDrag ? (
                  <SubPartData items={data} />
                ) : (
                  data?.map((d_data, d_index) => (
                    <TableRow
                      key={d_index}
                      className={`tbody-row ${
                        props.subData ? "cursor-pointer" : ""
                      }`}
                    >
                      <TableCell>
                        {d_data &&
                          d_data.rss_feeds &&
                          d_data.rss_feeds === true && (
                            <>
                              <span className="float-left">
                                <Tooltip title="RSS">
                                  <img
                                    src={RSS}
                                    alt="RSS"
                                    className="rss-logo"
                                  />
                                </Tooltip>
                              </span>
                            </>
                          )}
                        <Table>
                          <TableRow
                            key={d_data && d_data.id}
                            onClick={() => {
                              if (props.subData) {
                                if (clickedId === d_index) setClickedId(null);
                                else setClickedId(d_index);
                              }
                            }}
                          >
                            {exportOption && (
                              <TableCell className="checkbox-cell">
                                <CheckboxCustom
                                  value={
                                    d_data &&
                                    selectedRecords.includes(d_data[keyField])
                                  }
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    particularCheckBoxFn(
                                      d_data[[keyField]],
                                      e.target.checked
                                    );
                                  }}
                                  isChecked={
                                    d_data &&
                                    selectedRecords.includes(d_data[[keyField]])
                                  }
                                />
                              </TableCell>
                            )}

                            {data_keys &&
                              data_keys.length &&
                              data_keys.map((key, k_index) => (
                                <>
                                  {key.name &&
                                  (key.name === "edit_delete" ||
                                    key.name === "edit" ||
                                    key.name === "delete" ||
                                    key.name === "view_delete" ||
                                    key.name === "view_edit" ||
                                    key.name === "linkedin_view" ||
                                    key.name === "linkedin_view_delete" ||
                                    key.name === "add" ||
                                    key.name === "deny" ||
                                    key.name === "approve" ||
                                    key.name === "mentor" ||
                                    key.name === "buttonAction" ||
                                    key.name === "ambassador_status" ||
                                    key.name === "edit_message_delete" ||
                                    key.name === "restore_delete") ? (
                                    <>
                                      {key.name && key.name === "deny" && (
                                        <TableCell>
                                          <button
                                            className="naction-btn"
                                            onClick={() => {
                                              denyRequestFn(d_data);
                                            }}
                                          >
                                            <img src={Deny} alt="Deny" />
                                          </button>
                                        </TableCell>
                                      )}

                                      {key.name && key.name === "approve" && (
                                        <TableCell>
                                          <button
                                            className="naction-btn"
                                            onClick={() => {
                                              approveRequestFn(d_data);
                                            }}
                                          >
                                            <img src={Allow} alt="Allow" />
                                          </button>
                                        </TableCell>
                                      )}

                                      {key.name && key.name === "mentor" && (
                                        <TableCell>
                                          {d_data.mentor ? (
                                            <img
                                              alt="Mentor"
                                              src={MentorYes}
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                toggleMentorFn(d_data, d_index);
                                              }}
                                            />
                                          ) : (
                                            <img
                                              alt="Not mentor"
                                              src={MentorNo}
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                toggleMentorFn(d_data, d_index);
                                              }}
                                            />
                                          )}
                                        </TableCell>
                                      )}

                                      {key.name &&
                                        key.name === "view_delete" && (
                                          <TableCell className="text-right">
                                            <div className="table-actions">
                                              <Tooltip title="View Profile">
                                                <a
                                                  href
                                                  onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    props.viewIconClick &&
                                                      props.viewIconClick(
                                                        d_data
                                                      );
                                                  }}
                                                >
                                                  <img
                                                    src={EyeIcon}
                                                    alt={"View"}
                                                  />
                                                </a>
                                              </Tooltip>
                                              <Tooltip title="Delete">
                                                <a
                                                  href
                                                  onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    callDelete(d_data.id);
                                                  }}
                                                >
                                                  <img
                                                    src={Delete}
                                                    alt={"Delete"}
                                                  />
                                                </a>
                                              </Tooltip>
                                            </div>
                                          </TableCell>
                                        )}

                                      {key?.name === "edit_message_delete" && (
                                        <TableCell className="text-right">
                                          <div className="table-actions">
                                            {props?.logged_in_user_id ===
                                              d_data?.created_by_id && (
                                              <Tooltip title="Edit">
                                                <a
                                                  href
                                                  onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    setEditData(d_data);
                                                    setRedirect(true);
                                                  }}
                                                >
                                                  <img src={Edit} alt="Edit" />
                                                </a>
                                              </Tooltip>
                                            )}
                                            <Tooltip
                                              title={messageIconTooltipText}
                                            >
                                              <a
                                                href
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  e.stopPropagation();
                                                  props?.onMessageClick &&
                                                    props.onMessageClick(
                                                      d_data
                                                    );
                                                }}
                                              >
                                                <img
                                                  src={MessageIcon}
                                                  alt="Message"
                                                />
                                              </a>
                                            </Tooltip>
                                            <Tooltip title="Delete">
                                              <a
                                                href
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  if (
                                                    props.skipDeleteConfirmation
                                                  )
                                                    props.confirmDelete(
                                                      d_data.id
                                                    );
                                                  else callDelete(d_data.id);
                                                }}
                                              >
                                                <img
                                                  src={Delete}
                                                  alt="Delete"
                                                />
                                              </a>
                                            </Tooltip>
                                          </div>
                                        </TableCell>
                                      )}

                                      {key.name && key.name === "view_edit" && (
                                        <TableCell className="text-right">
                                          <div className="table-actions">
                                            <Tooltip title="View Profile">
                                              <a
                                                href
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  e.stopPropagation();
                                                  props.viewIconClick &&
                                                    props.viewIconClick(d_data);
                                                }}
                                              >
                                                <img
                                                  src={EyeIcon}
                                                  alt={"View"}
                                                />
                                              </a>
                                            </Tooltip>
                                            <Tooltip title="Edit">
                                              <a
                                                href
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  e.stopPropagation();
                                                  setEditData(d_data);
                                                  setRedirect(true);
                                                }}
                                              >
                                                <img src={Edit} alt="Edit" />
                                              </a>
                                            </Tooltip>
                                          </div>
                                        </TableCell>
                                      )}

                                      {key?.name === "linkedin_view" && (
                                        <TableCell className="text-right">
                                          <div className="table-actions">
                                            <div className="linkedin-column-combined">
                                              {!isNull(
                                                d_data.linkedin_public_url
                                              ) &&
                                                !isEmpty(
                                                  d_data.linkedin_public_url
                                                ) && (
                                                  <div>
                                                    <a
                                                      href={getClickableLink(
                                                        d_data.linkedin_public_url
                                                      )}
                                                      target="_blank"
                                                      rel="noopener noreferrer"
                                                    >
                                                      <img
                                                        className="h-40"
                                                        alt="complex"
                                                        src={Linkedin}
                                                      />
                                                    </a>
                                                  </div>
                                                )}
                                            </div>

                                            <Tooltip title="View Profile">
                                              <a
                                                href
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  e.stopPropagation();
                                                  props.viewIconClick &&
                                                    props.viewIconClick(d_data);
                                                }}
                                              >
                                                <img
                                                  src={EyeIcon}
                                                  alt={"View"}
                                                />
                                              </a>
                                            </Tooltip>
                                          </div>
                                        </TableCell>
                                      )}

                                      {key?.name === "linkedin_view_delete" && (
                                        <TableCell className="text-right">
                                          <div className="table-actions">
                                            <div className="linkedin-column-combined">
                                              {!isNull(
                                                d_data.linkedin_public_url
                                              ) &&
                                                !isEmpty(
                                                  d_data.linkedin_public_url
                                                ) && (
                                                  <div>
                                                    <a
                                                      href={getClickableLink(
                                                        d_data.linkedin_public_url
                                                      )}
                                                      target="_blank"
                                                      rel="noopener noreferrer"
                                                    >
                                                      <img
                                                        className="h-40"
                                                        alt="complex"
                                                        src={Linkedin}
                                                      />
                                                    </a>
                                                  </div>
                                                )}
                                            </div>
                                            <Tooltip title="View Profile">
                                              <a
                                                href
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  e.stopPropagation();
                                                  props.viewIconClick &&
                                                    props.viewIconClick(d_data);
                                                }}
                                              >
                                                <img
                                                  src={EyeIcon}
                                                  alt={"View"}
                                                />
                                              </a>
                                            </Tooltip>

                                            <Tooltip title="Delete">
                                              <a
                                                href
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  e.stopPropagation();
                                                  callDelete(d_data.id);
                                                }}
                                              >
                                                <img
                                                  src={Delete}
                                                  alt={"Delete"}
                                                />
                                              </a>
                                            </Tooltip>
                                          </div>
                                        </TableCell>
                                      )}

                                      {key.name && key.name === "delete" && (
                                        <TableCell className="text-right">
                                          <div className="table-actions">
                                            <Tooltip title="Delete">
                                              <a
                                                href
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  e.stopPropagation();
                                                  callDelete(d_data.id);
                                                }}
                                              >
                                                <img
                                                  src={Delete}
                                                  alt="Delete"
                                                />
                                              </a>
                                            </Tooltip>
                                          </div>
                                        </TableCell>
                                      )}

                                      {key.name && key.name === "edit" && (
                                        <TableCell className="text-right">
                                          <div className="table-actions">
                                            <Tooltip title="Edit">
                                              <a
                                                href
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  e.stopPropagation();
                                                  setEditData(d_data);
                                                  setRedirect(true);
                                                }}
                                              >
                                                <img src={Edit} alt="Edit" />
                                              </a>
                                            </Tooltip>
                                          </div>
                                        </TableCell>
                                      )}

                                      {key.name &&
                                        key.name === "edit_delete" && (
                                          <TableCell className="text-right">
                                            <div className="table-actions">
                                              {key.type ===
                                                "country_insight" && (
                                                <Tooltip title="Add City">
                                                  <a
                                                    href
                                                    onClick={(e) => {
                                                      e.preventDefault();
                                                      e.stopPropagation();
                                                      props.history.push({
                                                        pathname: `/post/add-country-city`,
                                                        state: {
                                                          country_id: d_data.id,
                                                          country_name:
                                                            d_data.country_name,
                                                        },
                                                      });
                                                    }}
                                                  >
                                                    <img
                                                      src={Add}
                                                      alt="Add City"
                                                    />
                                                  </a>
                                                </Tooltip>
                                              )}
                                              {!d_data.rss_feeds && (
                                                <>
                                                  {props?.editPermission?.(
                                                    d_data
                                                  ) === false ? (
                                                    <></>
                                                  ) : (
                                                    <Tooltip title="Edit">
                                                      <a
                                                        href
                                                        onClick={(e) => {
                                                          e.preventDefault();
                                                          e.stopPropagation();
                                                          setEditData(d_data);
                                                          setRedirect(true);
                                                        }}
                                                      >
                                                        <img
                                                          src={Edit}
                                                          alt="Edit"
                                                        />
                                                      </a>
                                                    </Tooltip>
                                                  )}
                                                </>
                                              )}
                                              {/* We need this function to excute record based conditions */}
                                              {props?.deletePermission?.(
                                                d_data
                                              ) === false ? (
                                                <></>
                                              ) : (
                                                <Tooltip
                                                  title={
                                                    props.confirmArchive
                                                      ? "Archive"
                                                      : "Delete"
                                                  }
                                                >
                                                  <a
                                                    href
                                                    onClick={(e) => {
                                                      e.preventDefault();
                                                      if (
                                                        props.skipDeleteConfirmation
                                                      )
                                                        props.confirmDelete(
                                                          d_data.id
                                                        );
                                                      else if (
                                                        props.confirmArchive
                                                      )
                                                        callArchive(d_data.id);
                                                      else
                                                        callDelete(d_data.id);
                                                    }}
                                                  >
                                                    <img
                                                      src={Delete}
                                                      alt="Delete"
                                                    />
                                                  </a>
                                                </Tooltip>
                                              )}
                                            </div>
                                          </TableCell>
                                        )}

                                      {key.name &&
                                        key.name === "restore_delete" && (
                                          <TableCell className="text-right">
                                            <div className="table-actions">
                                              {props?.deletePermission?.(
                                                d_data
                                              ) === false ? (
                                                <></>
                                              ) : (
                                                <>
                                                  <Tooltip title="Restore">
                                                    <a
                                                      href
                                                      onClick={(e) => {
                                                        e.preventDefault();
                                                        callRestore(d_data.id);
                                                      }}
                                                    >
                                                      <img
                                                        src={Restore}
                                                        alt="Restore"
                                                      />
                                                    </a>
                                                  </Tooltip>
                                                  <Tooltip title="Delete">
                                                    <a
                                                      href
                                                      onClick={(e) => {
                                                        e.preventDefault();
                                                        callDelete(d_data.id);
                                                      }}
                                                    >
                                                      <img
                                                        src={Delete}
                                                        alt="Delete"
                                                      />
                                                    </a>
                                                  </Tooltip>
                                                </>
                                              )}
                                            </div>
                                          </TableCell>
                                        )}

                                      {key.name &&
                                        key.name === "ambassador_status" && (
                                          <TableCell
                                            className={`ambassador-status`}
                                          >
                                            <span
                                              className={`${
                                                d_data.status === "not_invited"
                                                  ? "text-grey"
                                                  : "text-primary"
                                              }`}
                                            >
                                              {d_data.status === "not_invited"
                                                ? "Not invited"
                                                : d_data.status === "invited"
                                                ? "Invited"
                                                : d_data.status === "pending"
                                                ? "Pending"
                                                : d_data.status === "rejected"
                                                ? "Rejected"
                                                : d_data.status === "assigned"
                                                ? "Assigned"
                                                : "Unknown"}
                                            </span>

                                            {d_data.status === "assigned" && (
                                              <AssignedIcon />
                                            )}
                                            {d_data.status === "rejected" && (
                                              <RejectedIcon htmlColor="#8094ab" />
                                            )}
                                          </TableCell>
                                        )}

                                      {key.name &&
                                        key.name === "buttonAction" && (
                                          <TableCell className="button-action-row">
                                            {key.type === "primary" &&
                                            d_data.status === "assigned" ? (
                                              <span>
                                                Assigned <AssignedIcon />
                                              </span>
                                            ) : (
                                              <ButtonCustom
                                                type={
                                                  key.type
                                                    ? key.type
                                                    : "primary"
                                                }
                                                onClick={() => {
                                                  props.onRowActionClick &&
                                                    props.onRowActionClick(
                                                      d_data
                                                    );
                                                }}
                                              >
                                                {key.label}
                                              </ButtonCustom>
                                            )}
                                          </TableCell>
                                        )}
                                    </>
                                  ) : (
                                    <>
                                      {key && key.split ? (
                                        <>
                                          {d_data[key.name] &&
                                          d_data[key.name].length > 3 ? (
                                            <TableCell
                                              className={
                                                sortSelectedType === k_index
                                                  ? "main-text"
                                                  : ""
                                              }
                                            >
                                              {d_data[key.name].map(
                                                (dp_data, index) => (
                                                  <>
                                                    {index < 3
                                                      ? `${dp_data}, `
                                                      : ``}
                                                  </>
                                                )
                                              )}{" "}
                                              <a
                                                href
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  e.stopPropagation();
                                                }}
                                              >
                                                {d_data[key.name].length - 3}{" "}
                                                more
                                              </a>
                                            </TableCell>
                                          ) : (
                                            <TableCell
                                              className={
                                                sortSelectedType === k_index
                                                  ? "main-text"
                                                  : ""
                                              }
                                            >
                                              {d_data[key.name].join(", ")}
                                            </TableCell>
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          {key &&
                                          key.name === "all_school_name" ? (
                                            <TableCell
                                              className={
                                                sortSelectedType === k_index
                                                  ? "main-text"
                                                  : ""
                                              }
                                            >
                                              {d_data &&
                                              d_data["school"] &&
                                              d_data["school"]?.name
                                                ? d_data["school"].name
                                                : "-"}
                                            </TableCell>
                                          ) : key?.name === "global" ? (
                                            <TableCell
                                              className={
                                                sortSelectedType === k_index
                                                  ? "main-text"
                                                  : ""
                                              }
                                            >
                                              <div className="table-cell-manage">
                                                {d_data[key.name] === true
                                                  ? "Open for all"
                                                  : d_data[key.name] === false
                                                  ? "School specific"
                                                  : "N/A"}
                                                {d_data?.posted_by_super_admin && (
                                                  <div className="table-cell-manage__button">
                                                    <ButtonCustom
                                                      onClick={() => {
                                                        props?.onManageClick &&
                                                          props.onManageClick(
                                                            d_data
                                                          );
                                                      }}
                                                    >
                                                      Manage
                                                    </ButtonCustom>
                                                  </div>
                                                )}
                                              </div>
                                            </TableCell>
                                          ) : key?.name === "created_at" ? (
                                            <TableCell
                                              className={
                                                sortSelectedType === k_index
                                                  ? "main-text"
                                                  : ""
                                              }
                                            >
                                              {moment(d_data[key.name]).format(
                                                "DD MMM YYYY"
                                              )}
                                            </TableCell>
                                          ) : key?.name === "topic_type" ? (
                                            <TableCell
                                              className={
                                                sortSelectedType === k_index
                                                  ? "main-text"
                                                  : ""
                                              }
                                            >
                                              {capitalizeFirstLetter(
                                                d_data[key.name]
                                              )}
                                            </TableCell>
                                          ) : key &&
                                            key.type === "country_insight" ? (
                                            <TableCell
                                              className={
                                                sortSelectedType === k_index
                                                  ? "main-text"
                                                  : ""
                                              }
                                            >
                                              {!isEmpty(
                                                d_data && d_data[key.name]
                                                  ? d_data[key.name]
                                                  : ""
                                              ) && d_data[key.name] !== "NA" ? (
                                                <img
                                                  src={Available}
                                                  alt="Available"
                                                />
                                              ) : (
                                                <img
                                                  src={Notavailable}
                                                  alt="Not Available"
                                                />
                                              )}
                                            </TableCell>
                                          ) : (
                                            <TableCell
                                              className={
                                                sortSelectedType === k_index
                                                  ? "main-text"
                                                  : ""
                                              }
                                            >
                                              {d_data && key.name === "options"
                                                ? d_data[key.name]
                                                  ? JOB_POSITION_OPTIONS.find(
                                                      (rec) =>
                                                        rec.value ===
                                                        d_data[key.name]
                                                    ).label
                                                  : null
                                                : d_data[key.name]}
                                            </TableCell>
                                          )}
                                        </>
                                      )}
                                    </>
                                  )}
                                </>
                              ))}
                          </TableRow>
                        </Table>
                        {props.subData && (
                          <>
                            <Table
                              className={`collapse-table ${
                                clickedId !== null && clickedId === d_index
                                  ? "d-table"
                                  : "d-none"
                              }`}
                            >
                              {props.subDataType === "country_insights" ? (
                                <TableRow key={d_data.id}>
                                  <TableCell></TableCell>
                                  <TableCell>
                                    <div className="country-insights-links-wrapper">
                                      {props.subDataHeaders &&
                                        props.subDataHeaders.length &&
                                        props.subDataHeaders.map((sub_data) => (
                                          <div className="row">
                                            <div className="col-md-2">
                                              <h6>{sub_data.name}</h6>
                                            </div>
                                            <div className="col-md-10">
                                              <h5>
                                                {!isEmpty(
                                                  d_data[sub_data.key]
                                                ) &&
                                                d_data[sub_data.key] !==
                                                  "NA" ? (
                                                  <a
                                                    rel="noreferrer"
                                                    href={d_data[sub_data.key]}
                                                    target="_blank"
                                                  >
                                                    {d_data[sub_data.key]}
                                                  </a>
                                                ) : (
                                                  <span>N.A.</span>
                                                )}
                                              </h5>
                                            </div>
                                          </div>
                                        ))}
                                    </div>
                                  </TableCell>
                                  <TableCell></TableCell>
                                </TableRow>
                              ) : (
                                <TableRow key={d_data.id}>
                                  {exportOption && <TableCell></TableCell>}
                                  <TableCell>
                                    <div className="row">
                                      {props.subDataHeaders &&
                                        props.subDataHeaders.length &&
                                        props.subDataHeaders.map((sub_data) => (
                                          <div
                                            className={`col-md-${Math.floor(
                                              12 / props.subDataHeaders.length
                                            )}`}
                                          >
                                            <h6>{sub_data.name}</h6>
                                            <h5>{d_data[sub_data.key]}</h5>
                                          </div>
                                        ))}
                                    </div>
                                  </TableCell>
                                  {!equalSubTableSpacing && (
                                    <TableCell></TableCell>
                                  )}
                                </TableRow>
                              )}
                            </Table>
                          </>
                        )}

                        {props.subData &&
                          props.subDataType === "country_insights" &&
                          d_data.cities.length > 0 && (
                            <>
                              <Table
                                className={`collapse-table country-insight-cities ${
                                  clickedId !== null && clickedId === d_index
                                    ? "d-table"
                                    : "d-none"
                                }`}
                              >
                                {d_data.cities.map((city) => (
                                  <TableRow key={d_data.id}>
                                    <TableCell></TableCell>
                                    <TableCell>
                                      <div className="country-insights-links-wrapper">
                                        <div className="country-insights-cities-wrapper">
                                          <h4>{city.city_name}</h4>
                                          <div className="city-actions">
                                            <Tooltip title="Edit City">
                                              <a
                                                href
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  e.stopPropagation();
                                                  props.history.push({
                                                    pathname: `/post/add-country-city`,
                                                    search: `?id=${city.id}`,
                                                  });
                                                }}
                                              >
                                                <img src={Edit} alt="Edit" />
                                              </a>
                                            </Tooltip>
                                            <Tooltip title="Delete City">
                                              <a
                                                href
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  e.stopPropagation();
                                                  props.onCityDelete(city.id);
                                                }}
                                              >
                                                <img
                                                  src={Delete}
                                                  alt="Delete"
                                                />
                                              </a>
                                            </Tooltip>
                                          </div>
                                        </div>
                                        {[
                                          {
                                            key: "living_link",
                                            label: "Living",
                                          },
                                          {
                                            key: "moving_link",
                                            label: "Moving",
                                          },
                                          {
                                            key: "working_link",
                                            label: "Working",
                                          },
                                        ].map((link) => (
                                          <div className="row">
                                            <div className="col-md-2">
                                              <h6>{link.label}</h6>
                                            </div>
                                            <div className="col-md-10">
                                              <h5>
                                                {city[link.key] &&
                                                !isEmpty(city[link.key]) &&
                                                city[link.key] !== "NA" ? (
                                                  <a
                                                    rel="noreferrer"
                                                    href={city[link.key]}
                                                    target="_blank"
                                                  >
                                                    {city[link.key]}
                                                  </a>
                                                ) : (
                                                  <span>N.A.</span>
                                                )}
                                              </h5>
                                            </div>
                                          </div>
                                        ))}
                                      </div>
                                    </TableCell>
                                    <TableCell>&nbsp;</TableCell>
                                  </TableRow>
                                ))}
                              </Table>
                            </>
                          )}
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </>
            )}
          </Table>
        </TableContainer>

        {selectedRecords.length > 0 && (
          <div className="records-selection-bar">
            <h5 className="selction-text">
              {selectedRecords.length} out of {totalRecords}
            </h5>

            <a
              className="clear-all"
              href
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                selectAllFn(false);
              }}
            >
              Clear all
            </a>

            <div className="left-content">
              <IconButton
                onClick={() => {
                  props.onSelectiveExport(allChecked ? [] : selectedRecords);
                }}
              >
                <img src={ExportIcon} alt="export" />
                Export selection
              </IconButton>

              {props.bulkDelete && (
                <IconButton
                  onClick={() => {
                    setOpenDialog("Many");
                  }}
                >
                  <img src={DeleteWhite} alt="delete" />
                </IconButton>
              )}

              {selectiveAction && (
                <div className="ml-3">
                  <ButtonCustom
                    type="dark"
                    onClick={() => {
                      props.onSelectiveActionClick(
                        allChecked ? exportIDs : selectedRecords
                      );
                    }}
                  >
                    {selectiveAction}
                  </ButtonCustom>
                </div>
              )}
            </div>
          </div>
        )}

        {openDialog && (
          <Dialog
            open={openDialog}
            header={`Are you sure you want to ${
              openDialog === "Archive"
                ? "archive"
                : openDialog === "Restore"
                ? "restore"
                : "delete"
            } ${
              selectedRecords.length <= 1
                ? "this record"
                : `these ${selectedRecords.length} records`
            }?`}
            onClose={() => setOpenDialog(false)}
            onActionClick={DeleteFn}
            actionButtonLabel="Yes"
            className={"confirmation-dialogue-wrapper "}
          />
        )}
      </div>
    </>
  );
}
