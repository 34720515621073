import React from "react";
import { Slide, toast } from "react-toastify";

import { Parser } from "json2csv";
import moment from "moment";
import { get, isEmpty, isNull, isUndefined } from "underscore";

import { store } from "../redux/store";
import { checkSsoUser, getNewToken } from "../services/authenticationServices";
import {
  ACCESSIBILITY_GRAY_BTN_HOVER_COLOR,
  ACCESSIBILITY_GRAY_COLOR,
  ACCESSIBILITY_GRAY_OUTLINE_COLOR,
  ACCESSIBILITY_GRAY_TEXT_COLOR,
  ACCESSIBILITY_LIGHT_GRAY_COLOR,
  ACCESSIBILITY_SECONDARY_BTN_HOVER_COLOR,
  ACCESSIBILITY_SECONDARY_COLOR,
  GRAY_BTN_HOVER_COLOR,
  GRAY_COLOR,
  GRAY_OUTLINE_COLOR,
  GRAY_TEXT_COLOR,
  LIGHT_GRAY_COLOR,
  MEDIUM_GRAY_COLOR,
  SECONDARY_BTN_HOVER_COLOR,
  SECONDARY_COLOR,
} from "../styles/variables";

import NoUserAccessibilityIcon from "../assets/svg/noUser-acceessibility.svg";
import NoUserIcon from "../assets/svg/noUser.svg";
import Image1 from "../assets/svg/book-appointment/1.svg";
import Image2 from "../assets/svg/book-appointment/2.svg";
import Image3 from "../assets/svg/book-appointment/3.svg";
import Image4 from "../assets/svg/book-appointment/4.svg";

import config from "../config";

export const getSelectedSchoolId = () => {
  const state = store.getState();
  return get(state, ["loginReducer", "selectedSchool", "id"], null);
};

export const toastify = (type = "success", message = "", autoClose = 2000) => {
  const toastId = Math.random();
  switch (type) {
    case "success":
      toast.success(
        <div className="d-flex align-items-center">
          &nbsp; <div>{message}</div>
        </div>,
        {
          autoClose: autoClose,
          position: toast.POSITION.BOTTOM_CENTER,
          transition: Slide,
          closeOnClick: true,
          toastId: toastId,
        }
      );
      break;
    case "error":
      toast.error(
        <div className="d-block w-max-content">
          {message ? message : "Something went wrong!"}
        </div>,
        {
          autoClose: autoClose,
          position: toast.POSITION.BOTTOM_CENTER,
          transition: Slide,
          closeOnClick: true,
          toastId: toastId,
        }
      );
      break;
    case "info":
      toast.info(
        <div className="d-flex align-items-center">
          {/* {getToastifyIcon(type)} */}
          &nbsp; <div className="d-block w-max-content">{message}</div>
        </div>,
        {
          autoClose: autoClose,
          style: { width: "max-content" },
          position: toast.POSITION.BOTTOM_CENTER,
          transition: Slide,
          closeOnClick: true,
          toastId: toastId,
        }
      );
      break;
    case "warning":
      toast.warning(
        <div className="d-flex align-items-center">
          &nbsp; <div className="d-block w-max-content">{message}</div>
        </div>,
        {
          autoClose: autoClose,
          style: { width: "max-content" },
          position: toast.POSITION.BOTTOM_CENTER,
          transition: Slide,
          closeOnClick: true,
          toastId: toastId,
        }
      );
      break;
    default:
      break;
  }
};

export const exportData = ({
  data = [],
  fields = [],
  filename = "data",
  setIsLoading,
}) => {
  const json2csvParser = new Parser({ fields, delimiter: "," });
  const csv = json2csvParser.parse(data);

  const date = new Date();
  const [month, day, year] = [
    ("0" + (date.getMonth() + 1)).slice(-2),
    date.getDate(),
    date.getFullYear(),
  ];

  downloadFile(
    `${filename}_${year}${month}${day}.csv`,
    "data:text/csv;charset=UTF-8," + encodeURIComponent(csv),
    setIsLoading
  );
};

const downloadFile = (fileName, urlData, setIsLoading) => {
  try {
    let aLink = document.createElement("a");
    aLink.download = fileName;
    aLink.href = urlData;

    let event = new MouseEvent("click");
    aLink.dispatchEvent(event);

    toastify("error", "Your list was exported successfully. ");
  } catch (e) {
    toastify("error", "Unable to download the file. Please try again. ");
  } finally {
    if (setIsLoading) setIsLoading(false);
  }
};

export const isStringNumeric = (num) => {
  return !isNaN(num);
};

export const validateString = (value = "") => {
  return !value || isEmpty(value) || isEmpty(value.trim());
};

export const validateId = (value) => {
  return (
    !value || isEmpty(value.toString()) || isEmpty(value.toString().trim())
  );
};

export const validateNumber = (value = "") => {
  return !value || isEmpty(value);
};

export const validateName = (value = "") => {
  return value.trim().match(/^[A-Za-z\s]+$/) ? false : true;
};

export const validatePosition = (value = "") => {
  return value.trim().match(/^[A-Za-z0-9-\s]+$/) ? false : true;
};

export const validateSpecialChar = (value = "") => {
  // eslint-disable-next-line no-useless-escape
  return value.trim().match(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/)
    ? true
    : false;
};

export const validatePassword = (value = "") => {
  return value.trim().match(/(?=.*?[A-Z])(?=.*?[a-z])/) ? false : true;
};

export const validateEmail = (value = "") => {
  return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value.trim())
    ? false
    : true;
};

export const validateArray = (value = []) => {
  return !value || isEmpty(value);
};

export const validateObject = (value = []) => {
  return !value || isEmpty(value) || isNull(value);
};

export const validateFile = (value = []) => {
  return !value || isEmpty(value);
};

export const validateUrl = (value = "") => {
  let expres = new RegExp(
    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}|wwwcp\.[a-zA-Z0-9]+\.[^\s]{2,})/gi
  );
  return expres.test(value) ? false : true;
};

export const validateYear = (value = "") => {
  return /^\d{4}$/.test(value) ? false : true;
};

export const validateEnum = (value = "") => {
  return (
    isNull(value) ||
    isEmpty(value.toString()) ||
    isEmpty(value.toString().trim())
  );
};

export const validatePrice = (num = "") => {
  const value = num.toString();

  return (
    !value ||
    !/^\d{0,7}(\.\d{0,2})?$/.test(value) ||
    value.startsWith(".") ||
    value.endsWith(".") ||
    parseFloat(value) < 0
  );
};

export const getValidSearchText = (value = "") => {
  return value.replace(/[^a-zA-Z0-9\s@+.~_-]/g, "");
};

export const validateSchoolToken = (value = "") => {
  return value.trim().match(/^[A-Za-z0-9_-]+$/) ? false : true;
};

const createTime = (date, number, add) => {
  return moment(date).add(number, add).format("hh:mma");
};

export const getWebinarTimeStamp = (start_time, duration, timezone) => {
  return `${moment(start_time).format("hh:mma")}-${createTime(
    start_time,
    duration,
    "minutes"
  )} (${timezone})`;
};

export const getAppointmentTimeStamp = (start_time, duration, timezone) => {
  return `${moment(start_time).format("hh:mma")} - ${createTime(
    start_time,
    duration,
    "minutes"
  )}`;
};

export const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const capitalizeFirstLetterOnType = (string = "") => {
  if (string && string.trim().length > 0)
    return string.charAt(0).toUpperCase() + string.slice(1);
  else return "";
};

export const getInitials = (title, initialCount = 2) => {
  const matches = title && title !== null ? title.match(/\b(\w)/g) : [];
  if (matches && matches !== null) {
    return matches.splice(0, initialCount).join("").toLocaleUpperCase();
  }
  return "";
};

export const validateDomain = (value = "") => {
  return /\b((?=[a-z0-9-]{1,63}\.)(xn--)?[a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,63}\b/.test(
    value
  )
    ? false
    : true;
};

export const validateVersionNumber = (version) => {
  const versionRegex = /^\d+(\.\d+)*$/;
  return !versionRegex.test(version.trim());
};

export const downloadAttachment = (fileURL, fileName) => {
  fetch(fileURL)
    .then((response) => response.blob())
    .then(
      (blob) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        })
    )
    .then((dataUrl) => {
      let anchor = document.createElement("a");
      anchor.download = fileName;
      anchor.href = dataUrl;
      anchor.click();
    })
    .catch((error) => {});
};

export const dataURLtoFile = (dataurl, filename) => {
  let arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
};

export const getDateTimeFormatted = (createdDate) => {
  const date = moment(createdDate),
    today = moment();

  const diffDays = today.diff(date, "days"),
    diffYears = today.diff(date, "year");

  if (diffYears === 0 && diffDays === 0) {
    return "at " + moment(createdDate).format("hh:mm a");
  } else if (diffYears === 0 && diffDays === -1) {
    return "at " + moment(createdDate).format("hh:mm a") + " Yesterday";
  } else {
    return (
      "at " +
      moment(createdDate).format("hh:mm a") +
      " on " +
      moment(createdDate).format("dddd, MMM Do YYYY")
    );
  }
};

export const getClickableLink = (link = "") => {
  return link.startsWith("http://") || link.startsWith("https://")
    ? link
    : `http://${link}`;
};

export const getTextFromHTML = (text = "") => {
  return text.replace(/<(.|\n)*?>/g, "").replace(/&nbsp;/g, " ");
};

export const validGraphCount = (value = "") => {
  return !value || isUndefined(value) || isNull(value) || value <= 0
    ? false
    : true;
};

export const validateAttribute = (value = "") => {
  return !(!value || isUndefined(value) || isNull(value) || isEmpty(value));
};

export const validateNumberAttribute = (value = "") => {
  return !(
    !value ||
    isUndefined(value) ||
    isNull(value) ||
    isEmpty(value.toString())
  );
};

export const headers_with_token = () => {
  let keepSignedIn = localStorage.getItem("keepSignedIn");
  const authExpire = localStorage.getItem("authTokenExpiration");
  const authExpiration = moment(authExpire).unix();
  let currDate;
  if (keepSignedIn === 0) {
    currDate = moment().add(5, "minutes").unix();
    keepSignedIn = false;
  } else {
    currDate = moment().add(24, "hours").unix();
    keepSignedIn = true;
  }

  if (currDate >= authExpiration) {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    (async () => {
      const data = await getNewToken(keepSignedIn);
      localStorage.setItem("authToken", data.data.authentication_token);
      localStorage.setItem(
        "authTokenExpiration",
        data.data.auth_token_expiration
      );
    })();
  }

  if (localStorage.getItem("isLoginWithLinkedin")) {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    (async () => {
      const userId = localStorage.getItem("userId");
      const response = await checkSsoUser(userId);

      if (!response.success) {
        sessionStorage.clear();
        clearLocalStorage();
        applyAccessibilityHelp(false);
        // navigate(routes.LOGIN);
      }
    })();
  }

  // const authToken = localStorage.getItem("authToken");
  let state = store.getState();
  const authToken = get(
    state,
    ["loginReducer", "user", "authentication_token"],
    null
  );
  // const email = localStorage.getItem("email");
  // const authToken = "46f712d24d245dbd9115087f5c61b438";
  // const email = "dg@narola.email";
  return {
    headers: {
      "Content-Type": "application/json",
      // "X-User-Email": email,
      Authorization: authToken,
    },
  };
};

export const headers_new_token = () => {
  const authToken = localStorage.getItem("authToken");
  return {
    headers: {
      "Content-Type": "application/json",
      // "X-User-Email": email,
      Authorization: authToken,
    },
  };
};

export let NoUserImage =
  localStorage.getItem("accessibilityHelp") === "true"
    ? NoUserAccessibilityIcon
    : NoUserIcon;

export const applyAccessibilityHelp = (help) => {
  const body = document.querySelector("body");

  if (help === true || help === "true") {
    NoUserImage = NoUserAccessibilityIcon;
    body.style.setProperty("--secondary-color", ACCESSIBILITY_SECONDARY_COLOR);
    body.style.setProperty(
      "--light-gray-color",
      ACCESSIBILITY_LIGHT_GRAY_COLOR
    );
    body.style.setProperty("--medium-gray-color", ACCESSIBILITY_GRAY_COLOR);
    body.style.setProperty("--gray-color", ACCESSIBILITY_GRAY_COLOR);
    body.style.setProperty(
      "--secondary-color-btn-hover",
      ACCESSIBILITY_SECONDARY_BTN_HOVER_COLOR
    );
    body.style.setProperty(
      "--gray-color-btn-hover",
      ACCESSIBILITY_GRAY_BTN_HOVER_COLOR
    );
    body.style.setProperty("--gray-text-color", ACCESSIBILITY_GRAY_TEXT_COLOR);
    body.style.setProperty(
      "--gray-outline-color",
      ACCESSIBILITY_GRAY_OUTLINE_COLOR
    );
    document.body.classList.add("accessibility");
  } else {
    NoUserImage = NoUserIcon;
    body.style.setProperty("--secondary-color", SECONDARY_COLOR);
    body.style.setProperty("--light-gray-color", LIGHT_GRAY_COLOR);
    body.style.setProperty("--medium-gray-color", MEDIUM_GRAY_COLOR);
    body.style.setProperty("--gray-color", GRAY_COLOR);
    body.style.setProperty(
      "--secondary-color-btn-hover",
      SECONDARY_BTN_HOVER_COLOR
    );
    body.style.setProperty("--gray-color-btn-hover", GRAY_BTN_HOVER_COLOR);
    body.style.setProperty("--gray-text-color", GRAY_TEXT_COLOR);
    body.style.setProperty("--gray-outline-color", GRAY_OUTLINE_COLOR);
    document.body.classList.remove("accessibility");
  }
};

export const numberWithCommas = (number = "") => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const convertTime12To24 = (time) => {
  let hours = Number(time.match(/^(\d+)/)[1]);
  let minutes = Number(time.match(/:(\d+)/)[1]);
  if (time.indexOf("pm") !== -1 && hours < 12) hours = hours + 12;
  if (time.indexOf("am") !== -1 && hours === 12) hours = hours - 12;
  let sHours = hours.toString();
  let sMinutes = minutes.toString();
  if (hours < 10) sHours = "0" + sHours;
  if (minutes < 10) sMinutes = "0" + sMinutes;
  return sHours + ":" + sMinutes;
};

export const getUserApp = () => {
  return process.env.REACT_APP_VERSION ?? "";
};

export const getUserWindow = () => {
  let window_name = "";
  if (!navigator.userAgent) return window_name;

  if (navigator.userAgent.indexOf("Win") !== -1) window_name = "Windows OS";
  else if (navigator.userAgent.indexOf("Mac") !== -1) window_name = "Macintosh";
  else if (navigator.userAgent.indexOf("Linux") !== -1)
    window_name = "Linux OS";
  else if (navigator.userAgent.indexOf("Android") !== -1)
    window_name = "Android OS";
  else if (navigator.userAgent.indexOf("like Mac") !== -1) window_name = "iOS";
  return window_name;
};

export const getUserBrowser = () => {
  let browser_name = "";
  if (!navigator.userAgent) return browser_name;

  browser_name = navigator.userAgent.match(/chrome|chromium|crios/i)
    ? "chrome"
    : navigator.userAgent.match(/firefox|fxios/i)
    ? "firefox"
    : navigator.userAgent.match(/safari/i)
    ? "safari"
    : navigator.userAgent.match(/opr\//i)
    ? "opera"
    : navigator.userAgent.match(/edg/i)
    ? "edge"
    : "";

  return browser_name;
};

//Microsoft clarity

export const addMicrosoftClarity = () => {
  const MSCLARITY_ID = config().MSCLARITY_ID;
  (function (c, l, a, r, i, t, y) {
    c[a] =
      c[a] ||
      function () {
        (c[a].q = c[a].q || []).push(arguments);
      };
    t = l.createElement(r);
    t.async = 1;
    t.src = "https://www.clarity.ms/tag/" + i;
    y = l.getElementsByTagName(r)[0];
    y.parentNode.insertBefore(t, y);
  })(window, document, "clarity", "script", MSCLARITY_ID);
};

export const setMSClarityTagsAfterLogin = (user, isSsoUser = false) => {
  if (window.clarity) {
    window.clarity("identify", user.id.toString());
    window.clarity("set", "user_id", user.id.toString());
    window.clarity("set", "user_email", user.email);
    window.clarity("set", "user_name", user.first_name + " " + user.last_name);
    window.clarity("set", "user_admin_position", user.admin_position);
    window.clarity("set", "user_account_type", user.account_type);
    window.clarity("set", "user_user_type", user.user_type);
    window.clarity("set", "user_school_id", user.school_id.toString());
    window.clarity("set", "user_school_name", user.school_name);
    window.clarity("set", "user_isSsoUser", isSsoUser.toString());
  }
};

export const setMSClarityTagsOnLogin = (email, isToken = false) => {
  if (window.clarity) {
    if (isToken) {
      window.clarity("set", "token", email);
    } else {
      window.clarity("set", "email", email);
    }
  }
};

export const createFriendlyTitle = (title) => {
  return title?.length > 0
    ? title
        .split(" ")
        .map((name) => name.charAt(0).toUpperCase() + name.substring(1))
        .join(" ")
    : "";
};

export const getKitsData = (kitID, data) => {
  let filterData = data.find((i) => i.url_type === kitID);
  return filterData;
};

// Computes and formats delivery time of message
export const deliveryTime = (time) => {
  if (time) {
    return new Date(time)
      .toLocaleString("default", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      })
      .toLocaleLowerCase();
  } else return "";
};
export const modalData = [
  {
    id: 1,
    title: "Set your availability",
    description:
      "Select days and times when you are available to meet students either virtually or in-person.",
    image: Image1,
  },
  {
    id: 2,
    title: "Sync your existing calendars",
    description:
      "Integrate your work calendar so there are no conflicts or double bookings.",
    image: Image2,
  },
  {
    id: 3,
    title: "Streamline your meetings",
    description:
      "Sync you Zoom account to automatically generate Zoom invites for any virtual meetings.",
    image: Image3,
  },
  {
    id: 4,
    title: "Invite others",
    description:
      "Feel free to invite other campus administrators or service providers who can support your international students.",
    image: Image4,
  },
];

export const trapFocus = (element) => {
  if (element) {
    let focusableEls = element.querySelectorAll(
      'a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])'
    );
    let firstFocusableEl = focusableEls[0];
    let lastFocusableEl = focusableEls[focusableEls.length - 1];
    let KEYCODE_TAB = 9;
    element.addEventListener("keydown", function (e) {
      let isTabPressed = e.key === "Tab" || e.keyCode === KEYCODE_TAB;

      if (!isTabPressed) {
        return;
      }

      if (e.shiftKey) {
        if (document.activeElement === firstFocusableEl) {
          lastFocusableEl.focus();
          e.preventDefault();
        }
      } else {
        if (document.activeElement === lastFocusableEl) {
          firstFocusableEl.focus();
          e.preventDefault();
        }
      }
    });
  }
};

export const youtubeIdFromLink = (url) => {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);
  window.urlId = null;
  window.urlId = match && match[2].length === 11 ? match[2] : null;
  return window.urlId;
};

export const parseNotificationType = (notificationType) => {
  if (notificationType === "email_notification") {
    return "Email";
  } else if (notificationType === "push_notification") {
    return "Push";
  }
  return notificationType;
};

export const getNotificationType = (data) => {
  if (data?.notifications_type?.length > 0) {
    return data.notifications_type.map((notification) =>
      parseNotificationType(notification)
    );
  } else if (data?.notification?.length > 0) {
    return data.notification.map((notification) =>
      parseNotificationType(notification)
    );
  }
  return ["no_notification"];
};

export const capitalizeFirstLetterSentence = (inputSentence) =>
  inputSentence
    .toLowerCase()
    .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());

export const getFilenameFromLink = (link) =>
  new URL(link).pathname.split("/").pop();

export const isURL = (str) => URL.canParse(str);

export const cropperToFile = async (
  cropper,
  filename,
  fileHandler,
  applySizeLimitInMB,
  props,
  initialSize,
  quality = 0.9
) => {
  await cropper.getCroppedCanvas().toBlob(
    (blob) => {
      if (fileSizeExceeds(blob.size, initialSize)) {
        cropperToFile(
          cropper,
          filename,
          fileHandler,
          initialSize,
          props,
          initialSize,
          quality - 0.1
        );
      } else if (
        applySizeLimitInMB &&
        fileSizeExceeds(blob.size, applySizeLimitInMB) &&
        quality >= 0
      ) {
        cropperToFile(
          cropper,
          filename,
          fileHandler,
          applySizeLimitInMB,
          props,
          initialSize,
          quality - 0.1
        );
      } else {
        const imageFile = new File([blob], filename, {
          type: "image/jpeg",
        });
        fileHandler([imageFile], props);
      }
    },
    "image/jpeg",
    quality
  );
};

export const fileSizeExceeds = (uploadedSizeBytes, maxFileSizeMB) => {
  return parseInt(uploadedSizeBytes) / 1024 / 1024 > maxFileSizeMB;
};

export const onUploadReject = (error) => toastify("error", error);

export const formatNumber = (number = null) => {
  return number?.toLocaleString("en", {
    useGrouping: true,
  });
};

export const selectedSchoolExists = (school_list, selectedSchool) => {
  const findIndexResult = school_list?.findIndex(
    (school) => school?.id === selectedSchool?.id
  );

  return findIndexResult >= 0;
};

export const numberKFormatter = (num) => {
  return Math.abs(num) > 999
    ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
    : Math.sign(num) * Math.abs(num);
};

export const handleNumberDotKeyPress = (event) => {
  // Allow backspace, tab, delete, and arrow keys
  if (
    event.key === "Backspace" ||
    event.key === "Tab" ||
    event.key === "Delete" ||
    event.key === "ArrowLeft" ||
    event.key === "ArrowRight" ||
    event.key === "ArrowUp" ||
    event.key === "ArrowDown"
  ) {
    return;
  }

  // Allow numbers and dot
  if (!/^[0-9.]$/.test(event.key)) {
    event.preventDefault();
  }
};

/**
 * Preserve localstoarage even after logout
 */
export const clearLocalStorage = () => {
  const storedFeedbackDialogTimestamp = localStorage.getItem(
    `feedback_dialog_timestamp`
  );
  localStorage.clear();
  if (storedFeedbackDialogTimestamp)
    localStorage.setItem(
      `feedback_dialog_timestamp`,
      storedFeedbackDialogTimestamp
    );
};

export const validatePhoneNumber = (number = "", countryDialCode = "") => {
  // Validate number without country code
  const onlyNumber = number?.toString()?.replace(countryDialCode, "");
  return !onlyNumber || isEmpty(onlyNumber);
};

export const checkOnlyCountryCode = (number = "") => {
  return number.match(/^(\+?\d{1,3}|\d{1,4})$/gm);
};
